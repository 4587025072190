<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="工序名称">
					<c-input name="name"></c-input>
				</m-search-item>
			</m-search>

			<m-operate>
				<c-button v-if="auth('b_processes_edit')" color="sys" @click="createMachine">新建工序</c-button>
			</m-operate>

			<c-table ref="table" height="grow">

				<c-table-column
					label="工序编号"
					width="70"
					name="id"
				></c-table-column>
				
				<c-table-column
					label="工序名称"
					width="100"
					name="name"
				></c-table-column>
				
				<c-table-column
					label="用工单价"
					width="80"
					name="remark"
				>
				  <template #default="{data}">
						{{data.price}}
				  </template>
				</c-table-column>

				<c-table-column
					label="备注"
					width="300"
					name="remark"
				>
				  <template #default="{data}">
					<span v-if="data.remark">{{data.remark}}</span>
					<span v-else>--</span>
				  </template>
				</c-table-column>

				<c-table-column
					label="创建时间"
					width="140"
					name="create_date"
				></c-table-column>

				<c-table-column
					type="button"
					label="操作"
					width="120"
				>
				  <template v-slot="{data}">
					<c-table-button v-if="auth('b_processes_edit')" @click="editMachine(data)">编辑</c-table-button>
					<c-table-button v-if="auth('b_processes_edit')" @click="deleteMachine(data)">删除</c-table-button>
				  </template>
				</c-table-column>
			</c-table>
		</module>

		<c-dialog ref="produtionProcessesEdit" :title="editState ? '编辑工序' : '新建工序'" width="600" @resolve="submit">
			<c-form ref="produtionProcessesForm" unit-width="100%">
				<c-form-item label="工序名称" required>
					<c-input name="name"></c-input>
				</c-form-item>
				<c-form-item label="用工单价" required>
					<c-input name="price" type="number" decimal="5" range="0,99999" unit="元"></c-input>
				</c-form-item>
				<c-form-item label="备注说明">
					<c-input type="textarea" name="remark"></c-input>
				</c-form-item>
				<c-input type="hidden" name="id"></c-input>
			</c-form>
		</c-dialog>

	</page>
</template>

<script>
export default {
	name: 'm_processes',
	components: {
	},
	data() {
		return {
			editState: false
		}
	},
	computed: {
	},
	mounted() {
		this.$refs.search.search();
	},
	methods: {
		tabHandle(item) {
			this.$refs.search.search();
		},
		searchHandle(data) {
			this.$refs.table.load({
				url: '/dic/processes/list',
				data
			});
		},
		//创建工序
		createMachine() {
			this.editState = false;
			this.$refs.produtionProcessesForm.clear();
			this.$refs.produtionProcessesForm.set({});
			this.$refs.produtionProcessesEdit.open();
		},
		//编辑工序
		editMachine(val) {
			this.editState = true;
			this.$refs.produtionProcessesForm.clear();
			this.$refs.produtionProcessesForm.set(val);
			this.$refs.produtionProcessesEdit.open();
		},
		//删除工序
		deleteMachine(val){
			this.$confirm({
			  title: '确认',
			  message: '确定要删除工序【'+val.name+'】吗？',  // 提示内容,
			  buttonName: '确定',  // 确定按钮文字
			  cancelButton: true,  // 是否显示取消按钮
			  resolve: () => {
					this.request({
						url: '/dic/processes/delete',
						data: {id:val.id},
						loading: true,
						success: data => {
							this.$message({
								message: '删除成功',
								type: 'success'
							});
							this.$refs.table.update();
						}
					});
			  },  // 确定按钮回调
			  cancel: () => {},  // 取消按钮回调
			});
		},
		//提交保存
		submit(stop) {
			stop();
			this.$refs.produtionProcessesForm.submit({
				url: this.editState ? '/dic/processes/update' : '/dic/processes/add',
				rule: {
					name: '请填写工序名称',
					price: '请填写用工单价'
				},
				dataFormatter: data => {
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '保存成功',
						type: 'success'
					});
					this.$refs.table.update();
					this.$refs.produtionProcessesEdit.close();
				}
			});
		}
	}
}
</script>